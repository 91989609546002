<template>
  <v-app-bar
    id="app-bar"
    fixed
    app
    color="#fff"
    flat
    class="my-app-bar-style"
  >
    <v-btn
      class="mr-3"
      elevation="1"
      fab
      small
      @click="setDrawer(!drawer)"
    >
      <v-icon v-if="this.$store.state.drawer">
        mdi-chevron-left
      </v-icon>

      <v-icon v-else>
        mdi-chevron-right
      </v-icon>
    </v-btn>

    <v-spacer />

    <div class="mx-3" />
    <v-tooltip
      v-if="isUserAdmin"
      bottom
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="ml-2"
          min-width="0"
          v-bind="attrs"
          text
          color="#37474F"
          to="/settings"
          v-on="on"
        >
          <v-icon size="25">
            mdi-cog-outline
          </v-icon>
        </v-btn>
      </template>
      <span>Settings</span>
    </v-tooltip>
    <v-menu
      bottom
      left
      offset-y
      origin="top left"
      transition="scale-transition"
    >
      <template v-slot:activator="{ attrs, on: onMenu }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on: onToolTip }">
            <v-btn
              class="ml-2"
              min-width="0"
              text
              color="#37474F"
              v-bind="attrs"
              v-on="{...onMenu, ...onToolTip }"
              @click="clearUnRead()"
            >
              <v-badge
                color="red"
                overlap
                bordered
              >
                <template v-slot:badge>
                  <span>{{ unReadCount }}</span>
                </template>
                <v-img
                  src="../../../../assets/icons/bellicon.png"
                  width="22px"
                />
              </v-badge>
            </v-btn>
          </template>
          <span>Notifications</span>
        </v-tooltip>
      </template>

      <v-list
        :tile="false"
        nav
      >
        <div
          v-if="notifications.length !== 0"
          class="notification-style"
        >
          <app-bar-item
            v-for="(n, i) in notifications"
            :key="`item-${i}`"
          >
            <v-list-item-title> {{ n.event_name }} </v-list-item-title>
          </app-bar-item>
        </div>
        <div
          v-else
          class="no-notification-style"
        >
          <div class="text-center">
            <v-icon>
              mdi-bell-outline
            </v-icon>
            <p class="text-style">
              No Notifications Yet
            </p>
          </div>
        </div>
      </v-list>
    </v-menu>
    <div class="mx-3" />
    <v-menu
      bottom
      rounded
      left
    >
      <template v-slot:activator="{ on: onMenu }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on: onToolTip }">
            <v-btn
              icon
              x-small
              v-on="{...onMenu, ...onToolTip}"
            >
              <v-img
                src="../../../../assets/icons/avataricon.png"
                width="36px"
              />
            </v-btn>
          </template>
          <span>Profile</span>
        </v-tooltip>
      </template>
      <v-list class="dropdown-list-nav">
        <v-list-item-group
          color="primary"
        >
          <v-list-item
            @click="userProfilePage()"
          >
            <v-list-item-icon>
              <v-icon>mdi-account-circle</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Profile</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            @click="userSettingsPage()"
          >
            <v-list-item-icon>
              <v-icon>mdi-cog</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Settings</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            class="logout-item"
            @click="logOut()"
          >
            <v-list-item-icon>
              <v-icon class="color-red">
                mdi-logout
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="color-red">
                Logout
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>
    <partner-auth-check
      v-if="partnerCheck()"
      ref="partnerAuth"
    />
  </v-app-bar>
</template>

<script>
  // Components
  import { VHover, VListItem } from 'vuetify/lib';

  // Utilities
  import { mapState, mapMutations, mapGetters } from 'vuex';
  import PartnerAuthCheck from '../../partnerauth/PartnerAuthCheck.vue';
  import global from 'src/mixins/global';

  export default {
    name: 'DashboardCoreAppBar',

    components: {
      AppBarItem: {
        render (h) {
          return h(VHover, {
            scopedSlots: {
              default: ({ hover }) => {
                return h(VListItem, {
                  attrs: this.$attrs,
                  class: {
                    'black--text': !hover,
                    'white--text secondary elevation-isLogged12': hover,
                  },
                  props: {
                    activeClass: '',
                    dark: hover,
                    link: true,
                    ...this.$attrs,
                  },
                }, this.$slots.default);
              },
            },
          });
        },
      },
      'partner-auth-check': PartnerAuthCheck,
    },
    mixins: [global],
    props: {
      value: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      firstName: '',
      lastName: '',
      isUserAdmin: false,
    }),

    computed: {
      ...mapState(['drawer']),
      ...mapGetters({
        isLogged: 'isLogged',
        notifications: 'notifications/getMessages',
        readStatus: 'notifications/getUserRead',
        unReadCount: 'notifications/getUnReadCount',
      }),
      userProfile () {
        return this.$store.getters['userprofile/getUserProfile'];
      },
      organizationDetails () {
        return this.$store.getters['userprofile/getOrganizationDetails'];
      },
      isOrgGotPartnerAccount () {
        if (this.organizationDetails.partners === null) {
          return false;
        }
        return true;
      },
    },
    async mounted () {
      await this.$store.dispatch('userprofile/fetchUserProfile');
      await this.$store.dispatch('userprofile/fetchOrganizationDetails');
      this.firstName = this.userProfile.first_name[0];
      this.lastName = this.userProfile.last_name[0];
      setTimeout(() => {
        this.partnerCheck() && this.$refs.partnerAuth.partnerAuthCheckOnLoad();
      }, 10000);
    },
    methods: {
      ...mapMutations({
        setDrawer: 'SET_DRAWER',
      }),
      userProfilePage () {
        this.$router.push({ name: 'User Profile' }).catch(() => {});
      },
      userSettingsPage () {
        this.$router.push({ name: 'Settings' }).catch(() => {});
      },
      clearUnRead () {
        this.$store.dispatch('notifications/updateUserRead', true);
        this.$store.dispatch('notifications/updateUnReadCount', 0);
      },
      logOut () {
         this.$router.push({ name: 'Logout' });
      },
    },
  };
</script>
<style scoped>
.my-app-bar-style{
  border-bottom: 1px solid #ccc !important;
}
.my-app-bar-title-style {
  font-size: 16px !important;
  color: #37474F !important;
  font-weight: bold !important;
}
.my-avatar-style {
  margin-bottom: 8px;
}
.my-btn-style {
  margin-left: 5px;
  background-color: #263238 !important;
  margin-bottom: 0px !important;
}
.trial-expire-alert {
  color: #C62828;
  font-size: 18px;
  text-align: center;
  font-family: 'Times New Roman', Times, serif;
  font-weight: 600;
}
.my-icon-style {
  font-size: 35px;
  color: #C62828;
  margin-left:20px;
}
.dropdown-list-nav{
  min-width: 20px;
    top: 68px;
    left: 1760px;
    transform-origin: left top;
    z-index: 8;
}
.v-menu__content{
  top: 68px !important;
}
.v-list-item__icon{
  margin-right: 5px !important;
}
.logout-item{
  border-top: 1px solid #ccc;
  color: #C62828;
}
.logout-item v-list-item__title{
  color: #C62828;
}
.color-red{
  color: #C62828;
}
.no-notification-style{
  height: 100px;
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.notification-style{
  width: 300px;
}
.text-style {
  margin-bottom: 0px !important;
  color: rgb(141, 141, 141);
  font-size: 12px;
}
.v-menu__content {
  box-shadow: none;
  border: 1px solid #ccc;
}
@media (max-width: 600px) {
.my-icon-style {
  font-size: 20px;
  margin-left:0px;
}
}
@media (max-width: 900px) {
.my-app-bar-style {
  overflow-x:auto;
  white-space: nowrap;
  overflow-y: hidden;
}
}
</style>
