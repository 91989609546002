<template>
  <div>
    <v-dialog
      v-model="showModal"
      max-width="600"
      persistent
    >
      <v-card
        color="#ECEFF1"
      >
        <br>
        <h2 class="title-style">
          <v-icon class="icon-style">
            mdi-account-lock-open
          </v-icon> Authenticate
        </h2>
        <v-card-text class="text-body-1 text-center">
          <v-icon class="sad-icon-style">
            mdi-emoticon-sad
          </v-icon>
          <v-container>
            <p class="alert-style">
              We couldn't find your partner account !
            </p>
            <p
              class="my-p-style"
            >
              Please authenticate your <span class="partner-name">Autodesk</span> account to get the latest changes you made.
            </p>
          </v-container>
          <v-container v-if="showAuthProgress">
            <v-row
              class="fill-height"
              align-content="center"
              justify="center"
            >
              <v-col
                class="activation-alert"
                cols="12"
              >
                {{ authenticationProcessAlert }}
              </v-col>
              <v-col cols="6">
                <v-progress-linear
                  color="#FF3700"
                  indeterminate
                  rounded
                  height="10"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn
            class="mt-2"
            color="red darken-3"
            text
            @click="cancel()"
          >
            Not Now
          </v-btn>
          <v-spacer />
          <v-btn
            class="mt-2"
            color="#FF3700"
            text
            :disabled="btnDisable"
            @click="authorize()"
          >
            Authenticate
          </v-btn>
        </v-card-actions>
      </v-card>
      <centre-spinner
        :loading="loading"
      />
    </v-dialog>
    <overlay-alert
      :show-progress="showProgress"
      :process-alert="processAlert"
    />
  </div>
</template>

<script>
import spinner from 'src/views/dashboard/component/SpinnerCentre';
import OverlayAlert from 'src/views/dashboard/component/OverlayAlert';

  export default {
    name: 'PartnerAuthCheck',
    components: {
      'centre-spinner': spinner,
      'overlay-alert': OverlayAlert,
    },
    data: () => ({
      showModal: false,
      loading: false,
      btnDisable: false,
      showAuthProgress: false,
      authenticationProcessAlert: '',
      showProgress: false,
      processAlert: '',
    }),
    computed: {
      partnerAuthUri () {
        return this.$store.getters['partners/getPartnerAuthUri'];
      },
      isUserConfirmedForAuth () {
        return this.$store.getters['partners/getUserInputOnConfirmAuth'];
      },
    },
    watch: {
      showModal: function (newval, oldval) {
        if (newval === false) {
          this.$emit('closed');
        }
      },
    },
    methods: {
      async partnerAuthCheckOnLoad () {
        await this.$store.dispatch('partners/fetchPartnerAuthUri').then(() => {
          if (this.partnerAuthUri.auth_partner === 'Authorized') {
            this.getUserHubs();
            return;
          }
          this.showModal = true;
        });
      },
      async isPartnerAuthorized () {
        this.processAlert = 'Verifying...';
        this.showProgress = true;
        await this.$store.dispatch('partners/fetchPartnerAuthUri').then(() => {
          if (this.partnerAuthUri.auth_partner === 'Authorized') {
            this.getUserHubs();
            return;
          }
          this.showProgress = false;
          this.showModal = true;
        });
      },
      async getUserHubs () {
        await this.$store.dispatch('partners/fetchUserHubs').then(() => {
          this.showProgress = false;
          this.$emit('partner-authorized');
        }).catch(() => {
          this.showProgress = false;
        });
      },
      authorize () {
        this.btnDisable = true;
        this.$store.dispatch('partners/storeUserInputOnConfirmAuth', true);
        this.showAuthProgress = true;
        this.authenticationProcessAlert = 'Verifying...';
        setTimeout(() => {
          this.hitPartnerAuthUri();
        }, 2000);
      },
      hitPartnerAuthUri () {
        this.authenticationProcessAlert = 'Redirecting...';
        setTimeout(() => {
          window.location = this.partnerAuthUri.auth_uri;
        }, 1000);
      },
      cancel () {
        this.$store.dispatch('partners/storeUserInputOnConfirmAuth', false);
        this.showModal = false;
      },
    },
  };
</script>
<style scoped>
.title-style {
  color: #37474F;
  margin-left: 30px;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
}
.my-p-style {
  font-size: 20px;
  font-weight: bold;
  color: #546E7A;
  font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  text-align: center;
}
.alert-style {
  font-size: 25px;
  color: #37474F;
  font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  text-align: center;
  font-weight: bold;
}
.sad-icon-style {
  color: #C62828;
  font-size: 50px;
}
.partner-name {
  color: #FF3700;
  font-weight: bold;
}
.activation-alert {
  font-size: 20px;
  color: #37474F;
  font-weight: bold;
  text-align: center;
}
</style>
